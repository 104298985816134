//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
class Edit extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        const SO268R = window.location.origin + "/client-edit/"
        //Element
        const R96278 = window.location.href.replace( SO268R, "" )
        //Element
        this.state = {
            //Element
            P6631T : [],
            //Element
            CF3Y39 : [],
            //Element
            OD894D : R96278,
        }
    }
    //Element
    I46G4X = () => {
        //Element
        const D0IQ34 = async() => {
            //Element
            const D4H7I7 = await Axios.get( "https://public.benozgurtosun.com/category", {} )
            //Element
            this.setState({
                //Element
                P6631T : D4H7I7.data
            })
        }
        //Element
        D0IQ34()
    }
    //Element
    QE413T = () => {
        //Element
        const J1791R = async() => {
            //Element
            const D4H7I7 = await Axios.get( "https://public.benozgurtosun.com/client/select", {
                //Element
                params : {
                    //Element
                    ID : this.state.OD894D
                }
            } )
            //Element
            this.setState({
                //Element
                CF3Y39 : D4H7I7.data
            })
        }
        //Element
        J1791R()
    }
    //Element
    J535CY = () => {
        //Element
        const I801CU = document.getElementsByClassName( "p43687" )[0]
        //Element
        const XDX942 = document.getElementsByClassName( "klf458" )[0]
        //Element
        const F9F8Y7 = document.getElementById( "Web_O0190T" )
        //Element
        let H422C2 = "https://public.benozgurtosun.com/images/"
        //Element
        let P1U7W1 = ""
        //Element
        let M165M1 = ""
        //Element
        Axios.get( "https://public.benozgurtosun.com/client/select", {
            //Element
            params : {
                //Element
                ID : this.state.OD894D
            }
        })
        //Element
        .then( A086S1 => {
            //Element
            A086S1.data.map( (Y97K82) => {
                //Element
                I801CU.classList.add( "d-none" )
                //Element
                XDX942.classList.remove( "d-none" )
                //Element
                P1U7W1     = Y97K82.Photo
                //Element
                M165M1     = H422C2 + P1U7W1
                //Element
                F9F8Y7.src = M165M1
                //Element
                sessionStorage.getItem( "Web_N518EM", Y97K82.Photo )
            })
        })
    }
    //Element
    K37Z59 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let INW968, CMR289, G7Y3U9, TT0G95, Y84U17, D04498, C575NQ, K5251P
        //Element
        INW968 = document.getElementById( "Web_P65395" ).value
        //Element
        CMR289 = document.getElementById( "Web_Y71949" ).value
        //Element
        G7Y3U9 = document.getElementById( "Web_U45Q45" ).value
        //Element
        TT0G95 = document.getElementById( "Web_GV376I" ).value
        //Element
        Y84U17 = sessionStorage.getItem( "Web_N518EM" )
        //Element
        D04498 = document.getElementById( "Web_N5X6E9" ).files.length
        //Element
        C575NQ = document.getElementsByClassName( "p43687" )[0]
        //Element
        K5251P = document.getElementsByClassName( "uh0823" )[0]
        //Element
        const Q1E36Z = async() => {
            //Element
            const C1NW86 = await Axios.get( "https://public.benozgurtosun.com/client/update", {
                //Element
                params : {
                    //Element
                    ID       : this.state.OD894D,
                    //Element
                    Title    : G7Y3U9,
                    //Element
                    Category : TT0G95,
                    //Element
                    Photo    : Y84U17,
                    //Element
                    Date     : INW968,
                    //Element
                    Admin    : CMR289
                }
            })
            //Element
            .then( T9323L => {
                //Element
                window.location.href = "/client-order"
            })
        }
        //Element
        Q1E36Z()
    }
     //Element
     G7Z12B = (e) => {
        //Element
        e.preventDefault()
        //Element
        const V2G997 = this.state.OD894D
        //Element
        const K0E12S = async() => {
            //Element
            const K3E531 = await Axios.get( "https://public.benozgurtosun.com/client/delete", {
                //Element
                params : {
                    //Element
                    ID : V2G997
                }
            })
            //Element
            .then( U19X07 => {
                //Element
                window.location.href = "/client-order"
            })
        }
        //Element
        K0E12S()
    }
    //Element
    componentDidMount(){
        //Element
        this.I46G4X()
        //Element
        this.QE413T()
        //Element
        this.J535CY()
    }
    //Element
    render() {
        //Element
        let OD894D = this.state.OD894D
        //Element
        let D47X0N = new Date()
        //Element
        let N6E721 = D47X0N.toLocaleDateString( "tr-TR" )
        //Element
        this.state.CF3Y39.map( K64378 => {
            //Element
            document.getElementById( "Web_V180MF" ).value = K64378.ID
            //Element
            document.getElementById( "Web_U45Q45" ).value = K64378.Title
            //Element
            document.getElementById( "Web_GV376I" ).value = K64378.Category
            //Element
            sessionStorage.setItem( "Web_N518EM", K64378.Photo )
        })
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                //Element
                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                    //Element
                    "Client"
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                    //Element
                    "Home - Our Client"
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                //Element
                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "ID"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_V180MF", name: "Web_V180MF", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Date"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_P65395", name: "Web_P65395", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: N6E721, required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Admin"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_Y71949", name: "Web_Y71949", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: "Cover-Studio", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Client"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_U45Q45", name: "Web_U45Q45", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Category"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_GV376I", name: "Web_GV376I", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq", onChange: this.N0V48A },
                                    //Element
                                    this.state.P6631T.map( (H14754) => {
                                        //Element
                                        return React.createElement( "option", { key: H14754.ID, value: H14754.ID },
                                            //Element
                                            H14754.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                            //Element
                                            React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: this.G7Z12B },
                                                //Element
                                                "Delete"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                            //Element
                                            React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: this.K37Z59 },
                                                //Element
                                                "Upgrade"
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Edit;