//Install
import React, { Component } from "react";
//Install
import { ReactComponent as IE181A } from "../../Media/Icon/002.svg";
//Install
import { ReactComponent as J7O7F9 } from "../../Media/Icon/003.svg";

//Element
class Social extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 a7612o" },
            //Element
            React.createElement( "button", { id: "Web_YN2710", name: "Web_YN2710", className: "d-flex w-100 justify-content-start align-items-center p-0 ps-4 pe-4 m-0 mt-4 mb-2 o54h06", type: "button" },
                //Element
                React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 e68c9l" },
                    //Element
                    React.createElement( IE181A, {} )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 lh-1 f816t2" },
                    //Element
                    "Sign in with Google"
                )
            ),
            //Element
            React.createElement( "button", { id: "Web_Q45T5E", name: "Web_Q45T5E", className: "d-flex w-100 justify-content-start align-items-center p-0 ps-4 pe-4 m-0 ms-2 mt-4 mb-2 q336d4", type: "button" },
                //Element
                React.createElement( "i", { className: "float-start w-auto p-0 m-0 lh-1 r94f3m" },
                    //Element
                    React.createElement( J7O7F9, {} )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 lh-1 g3ov57" },
                    //Element
                    "Sign in with Apple"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Social;