//Install
import React, { useState, useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Install
import Header from "../../Header";
//Install
import Footer from "../../Footer";

//Element
function A5L82C(){
    //Element
    document.title = "Hello - Cover Studio"
}
//Element
function KG878G(e){
    //Element
    e.preventDefault()
    //Element
    let LSO193, Z7E189, C9H968, Q577R7, YW9380, QFP608, BW052E, B75214
    //Element
    LSO193 = document.getElementById( "Web_QE203J" ).value
    //Element
    Z7E189 = document.getElementById( "Web_R142ZN" ).value
    //Element
    C9H968 = document.getElementById( "Web_Y6672V" ).value
    //Element
    Q577R7 = document.getElementById( "Web_X34056" ).value
    //Element
    YW9380 = document.getElementById( "Web_P11088" ).value
    //Element
    QFP608 = document.getElementById( "Web_W93O9Q" ).value
    //Element
    BW052E = document.getElementById( "Web_NO978T" ).value
    //Element
    B75214 = document.getElementById( "Web_D71M7S" ).value
    //Element
    const C2629X = async() => {
        //Element
        const Y78M19 = await Axios.get( "https://public.benozgurtosun.com/branding/update", {
            //Element
            params : {
                //Element
                Title       : Z7E189,
                //Element
                Description : C9H968,
                //Element
                Strategy    : Q577R7,
                //Element
                Logo        : YW9380,
                //Element
                Visual      : QFP608,
                //Element
                Message     : BW052E,
                //Element
                Guide       : B75214
            }
        })
        //Element
        .catch( V04D26 => {
            //Element
            console.log( V04D26 )
        })
    }
    //Element
    C2629X()
    //Element
    window.location.href = "/services/branding-and-identity"
}
//Element
function Branding(){
    //Element
    A5L82C()
    //Element
    let YS014L = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
    //Element
    let E34245 = new Date()
    //Element
    let E89J9A = E34245.getDate()
    //Element
    let L0047O = YS014L[ E34245.getMonth() ]
    //Element
    let H8E93O = E34245.getFullYear()
    //Element
    let R991JQ = E34245.getHours()
    //Element
    let A4621K = E34245.getMinutes()
    //Element
    let B348QM = ":"
    //Element
    let P10047 = " "
    //Element
    let D767O5 = E89J9A + P10047 + L0047O + P10047 + H8E93O + P10047
    //Element
    let T8061C = E34245.toLocaleDateString( "tr-TR" )
    //Element
    let LSO193, Z7E189, C9H968, Q577R7, YW9380, QFP608, BW052E, B75214
    //Element
    const [ XN336I, F757J5 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const H75D46 = async() => {
            //Element
            const W5L58W = await Axios.get( "https://public.benozgurtosun.com/branding", {} )
            //Element
            F757J5( W5L58W.data )
        }
        //Element
        H75D46()
    }, [] )
    //Element
    XN336I.map( (J3670X) => {
        //Element
        LSO193 = J3670X.ID
        //Element
        Z7E189 = J3670X.Title
        //Element
        C9H968 = J3670X.Description
        //Element
        Q577R7 = J3670X.Strategy
        //Element
        YW9380 = J3670X.Logo
        //Element
        QFP608 = J3670X.Visual
        //Element
        BW052E = J3670X.Message
        //Element
        B75214 = J3670X.Guide
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Hello, Cover Studio", Status: D767O5 } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ga8x38" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                                    //Element
                                    "Branding and Identity"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                                    //Element
                                    "Home - Services"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                                //Element
                                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: KG878G },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "ID"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_QE203J", name: "Web_QE203J", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: LSO193, type: "text", disabled: "disabled" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Title"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_R142ZN", name: "Web_R142ZN", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Z7E189, type: "text", required: "required" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Description"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_Y6672V", name: "Web_Y6672V", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: C9H968, type: "text", required: "required" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Brand Strategy"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_X34056", name: "Web_X34056", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Q577R7, type: "text", required: "required" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Logo Design"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_P11088", name: "Web_P11088", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: YW9380, type: "text", required: "required" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Visual Identity"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_W93O9Q", name: "Web_W93O9Q", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: QFP608, type: "text", required: "required" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Brand Messaging"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_NO978T", name: "Web_NO978T", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: BW052E, type: "text", required: "required" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Brand Guidelines"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_D71M7S", name: "Web_D71M7S", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: B75214, type: "text", required: "required" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "button", { id: "Web_N2G4F1", name: "Web_N2G4F1", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                                            //Element
                                            "Submit"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Branding;