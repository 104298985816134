//Install
import React, { Component } from "react";
//Install
import { ReactComponent as M8042H } from "../../Media/Icon/014.svg";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 k347lf" },
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/overview" },
                    //Element
                    "Home"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/about" },
                    //Element
                    "About"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/media" },
                    //Element
                    "Media"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/aim" },
                    //Element
                    "Aim"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                    //Element
                    "Services"
                ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute yj724o" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/branding-and-identity" },
                            //Element
                            "Branding and Identity",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/graphic-design" },
                            //Element
                            "Graphic Design",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/web-design-and-development" },
                            //Element
                            "Web Design and Development",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/social-media-management" },
                            //Element
                            "Social Media Management",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/influencer-marketing" },
                            //Element
                            "Influencer Marketing",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/content-creation" },
                            //Element
                            "Content Creation",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/marketing-campaign" },
                            //Element
                            "Marketing Campaign",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/photography-and-video" },
                            //Element
                            "Photography and Videography",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/copywriting" },
                            //Element
                            "Copywriting",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/seo-and-digital-marketing" },
                            //Element
                            "SEO and Digital Marketing",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/event-planning-and-management" },
                            //Element
                            "Event Planning and Management",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/services/brand-monitoring-and-analysis" },
                            //Element
                            "Brand Monitoring and Analysis",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/client-order" },
                    //Element
                    "Our Client"
                ),
                //Element
                React.createElement( "ul", { className: "float-start w-100 p-0 pt-2 pb-2 m-0 position-absolute yj724o" },
                    //Element
                    React.createElement( "li", { className: "float-start w-100 p-0 ps-3 pe-3 m-0 position-relative" },
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center position-relative", href: "/client" },
                            //Element
                            "New Client",
                            //Element
                            React.createElement( M8042H, {} )
                        )
                    ),
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/category" },
                    //Element
                    "Category"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto position-relative" },
                //Element
                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/contact" },
                    //Element
                    "Contact"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;