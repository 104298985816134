//Install
import React from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Header from "../../Header";
//Install
import Footer from "../../Footer";
//Install
import List from "./List";

//Element
function A5L82C(){
    //Element
    document.title = "Hello - Cover Studio"
}
//Element
function Order(){
    //Element
    A5L82C()
    //Element
    let YS014L = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
    //Element
    let E34245 = new Date()
    //Element
    let E89J9A = E34245.getDate()
    //Element
    let L0047O = YS014L[ E34245.getMonth() ]
    //Element
    let H8E93O = E34245.getFullYear()
    //Element
    let R991JQ = E34245.getHours()
    //Element
    let A4621K = E34245.getMinutes()
    //Element
    let B348QM = ":"
    //Element
    let P10047 = " "
    //Element
    let D767O5 = E89J9A + P10047 + L0047O + P10047 + H8E93O + P10047
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Hello, Cover Studio", Status: D767O5 } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ga8x38" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( List, {} )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Order;