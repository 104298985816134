//Install
import React from "react";
//Install
import Login from "./Login/Default";
//Install
import Social from "./Login/Social";
//Install
import { ReactComponent as BY424S } from "../Media/Icon/001.svg";

//Element
function Overview(){
    //Element
    document.title = "Hello - Cover Studio"
    //Element
    let F8401L = document.getElementsByTagName( "body" )[0]
    //Element
    F8401L.classList.add( "overflow-hidden" )
}
//Element
function Main(){
    //Element
    Overview()
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 vh1695" },
        //Element
        React.createElement( "div", { className: "float-start w-50 p-0 m-0 rdx705" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-5 m-0 x6e705" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 b8z233" },
                    //Element
                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 mb-2 j142ro" },
                        //Element
                        "Dashboard"
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 mb-2 lh-1 o179ee" },
                        //Element
                        "Login"
                    )
                ),
                //Element
                React.createElement( Social, {} ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-4 mb-2 ou9216" },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 v39h05" },
                        //Element
                        React.createElement( "label", { className: "float-start w-auto p-0 m-0 text-center lh-1 yq281l" },
                            //Element
                            "Or with email"
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 euw985" },
                    //Element
                    React.createElement( Login, {} ),
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 d835tf" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 mt-4 lh-1 c1a625" },
                            //Element
                            "Still not a member?"
                        ),
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-4 ms-1 lh-1 tr04h0", href: "/" },
                            //Element
                            "Sign up"
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-50 p-0 m-0 mk89k1" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 p4h737" },
                //Element
                React.createElement( BY424S, {} )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Main;