//Install
import React, { Component } from "react";
//Install
import { ReactComponent as W081ZO } from "../../Media/Icon/006.svg";
//Install
import { ReactComponent as B714AF } from "../../Media/Icon/007.svg";
//Install
import { ReactComponent as T53G0V } from "../../Media/Icon/008.svg";
//Install
import { ReactComponent as R46047 } from "../../Media/Icon/009.svg";
//Install
import { ReactComponent as HGS178 } from "../../Media/Icon/010.svg";

//Element
class SET extends Component {
    //Element
    U9I328 = (e) => {
        //Element
        e.preventDefault()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 m95t97" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 me-3", href: "/", onClick: this.U9I328 },
                    //Element
                    React.createElement( HGS178, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 me-3", href: "/" },
                    //Element
                    React.createElement( T53G0V, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 me-3", href: "/media" },
                    //Element
                    React.createElement( B714AF, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0", href: "/contact" },
                    //Element
                    React.createElement( W081ZO, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default SET;