//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import GET from "./Function/GET";
//Install
import SET from "./Header/SET";
//Install
import Menu from "./Header/Menu";
//Install
import Brand from "./Header/Brand";
//Install
import propTypes from "prop-types";

//Element
class Header extends Component {
    //Element
    render() {
        //Element
        if( GET( "XO3GV9X74Q4M" ) != "BH5MY33X21RR" ){
            //Element
            window.location.href = "/"
        }
        //Element
        const { Title, Status } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 h723jp" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 r0062c" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 b177uj" },
                                //Element
                                React.createElement( Brand, {} )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( Menu, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( SET, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 i6d476" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 y5q57d" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 j580ji" },
                                    //Element
                                    Title
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 ji6562" },
                                    //Element
                                    Status
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Header.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Status : propTypes.string.isRequired
}
//Element
export default Header;