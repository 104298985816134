//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
class New extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            P6631T : [],
            //Element
            U13KG9 : [],
            //Element
            HR384Q : [],
            //Element
            QU1204 : [],
            //Element
            F984US : [],
            //Element
            N6I51E : []
        }
    }
    //Element
    I46G4X = () => {
        //Element
        const D0IQ34 = async() => {
            //Element
            const D4H7I7 = await Axios.get( "https://public.benozgurtosun.com/category", {} )
            //Element
            this.setState({
                //Element
                P6631T : D4H7I7.data
            })
        }
        //Element
        D0IQ34()
    }
    //Element
    TV0805 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let INW968, CMR289, G7Y3U9, TT0G95, Y84U17, D04498, C575NQ, K5251P
        //Element
        INW968 = document.getElementById( "Web_P65395" ).value
        //Element
        CMR289 = document.getElementById( "Web_Y71949" ).value
        //Element
        G7Y3U9 = document.getElementById( "Web_U45Q45" ).value
        //Element
        TT0G95 = document.getElementById( "Web_GV376I" ).value
        //Element
        Y84U17 = sessionStorage.getItem( "Web_N518EM" )
        //Element
        D04498 = document.getElementById( "Web_N5X6E9" ).files.length
        //Element
        C575NQ = document.getElementsByClassName( "p43687" )[0]
        //Element
        K5251P = document.getElementsByClassName( "uh0823" )[0]
        //Element
        if( D04498 == "0" ){
            //Element
            window.scrollTo({
                //Element
                top      : 0,
                //Element
                behavior : "smooth" 
            })
            //Element
            C575NQ.style.border = "1px solid #1B84FF"
            //Element
            K5251P.innerHTML    = "Upload A Photograph."
        //Element
        } else {
            //Element
            if( D04498 == "0" ){
                //Element
                document.getElementById( "Web_V32KA9" ).style.border = "1px solid #1B84FF"
                //Element
                document.querySelector( 'label[for="Web_R494VD"]' ).innerHTML = "Upload A Photograph."
            //Element
            } else {
                //Element
                const U09H0S = async() => {
                    //Element
                    const K1308D = await Axios.get( "https://public.benozgurtosun.com/client/add", {
                        //Element
                        params : {
                            //Element
                            Title    : G7Y3U9,
                            //Element
                            Category : TT0G95,
                            //Element
                            Photo    : Y84U17,
                            //Element
                            Date     : INW968,
                            //Element
                            Admin    : CMR289
                        }
                    })
                    //Element
                    .then( I2L9R9 => {
                        //Element
                        window.location.href = "/client"
                    })
                }
                //Element
                U09H0S()
            }
        }
    }
    //Element
    componentDidMount(){
        //Element
        this.I46G4X()
    }
    //Element
    render() {
        //Element
        let E6J29T = new Date()
        //Element
        let K0JH42 = E6J29T.toLocaleDateString( "tr-TR" )
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                //Element
                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                    //Element
                    "Client"
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                    //Element
                    "Home - Our Client"
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                //Element
                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: this.TV0805 },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "ID"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_V180MF", name: "Web_V180MF", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Date"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_P65395", name: "Web_P65395", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: K0JH42, required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Admin"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_Y71949", name: "Web_Y71949", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: "Cover-Studio", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Client"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_U45Q45", name: "Web_U45Q45", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Category"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_GV376I", name: "Web_GV376I", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq", onChange: this.N0V48A },
                                    //Element
                                    this.state.P6631T.map( (H14754) => {
                                        //Element
                                        return React.createElement( "option", { key: H14754.ID, value: H14754.ID },
                                            //Element
                                            H14754.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                //Element
                                React.createElement( "button", { id: "Web_D845DF", name: "Web_D845DF", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                                    //Element
                                    "Submit"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default New;