//Install
import React from "react";
//Install
import J5110X from "../Function/SET";

//Element
function Login(e){
    //Element
    e.preventDefault()
    //Element
    let OS40G3 = document.getElementById( "Web_N7A78U" ).value
    //Element
    let BW06P1 = document.getElementById( "Web_U7A6L9" ).value
    //Element
    if( OS40G3 == "Admin" && BW06P1 == "QL907SK4W62C" ){
        //Element
        J5110X( "XO3GV9X74Q4M", "BH5MY33X21RR", 365 )
        //Element
        window.location.href = "/overview"
    //Element
    } else {
        //Element
        window.location.href = "/"
    }
}
//Element
function Default(){
    //Element
    const Content = React.createElement( "form", { id: "Web_D52JP7", name: "Web_D52JP7", className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 c479ca", method: "GET" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d835tf" },
            //Element
            React.createElement( "input", { id: "Web_N7A78U", name: "Web_N7A78U", className: "float-start w-100 p-0 ps-4 pe-4 m-0 y9sw59", placeholder: "Username", required: "required" } )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d835tf" },
            //Element
            React.createElement( "input", { id: "Web_U7A6L9", name: "Web_U7A6L9", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 y9sw59", placeholder: "Password", type: "password", required: "required" } )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 text-end d835tf" },
            //Element
            React.createElement( "a", { className: "float-end w-100 p-0 m-0 mt-3 text-end lh-1 k5h5x5", href: "/" },
                //Element
                "Forgot Password"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d835tf" },
            //Element
            React.createElement( "button", { id: "Web_R160M0", name: "Web_R160M0", className: "float-start w-100 p-0 m-0 mt-4 p2076q", type: "submit", onClick: Login },
                //Element
                "Sign In"
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;