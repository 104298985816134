//Install
import React from "react";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Main from "./Component/Main";
//Install
import Overview from "./Component/Overview/Default";
//Install
import About from "./Component/Tab/About/Default";
//Install
import Media from "./Component/Tab/Media/Default";
//Install
import Aim from "./Component/Tab/Aim/Default";
//Install
import Branding from "./Component/Tab/Services/Branding";
//Install
import Graphic from "./Component/Tab/Services/Graphic";
//Install
import Development from "./Component/Tab/Services/Development";
//Install
import Social from "./Component/Tab/Services/Social";
//Install
import Influencer from "./Component/Tab/Services/Influencer";
//Install
import Creation from "./Component/Tab/Services/Creation";
//Install
import Campaign from "./Component/Tab/Services/Campaign";
//Install
import Photography from "./Component/Tab/Services/Photography";
//Install
import Copywriting from "./Component/Tab/Services/Copywriting";
//Install
import SEO from "./Component/Tab/Services/SEO";
//Install
import Event from "./Component/Tab/Services/Event";
//Install
import Analysis from "./Component/Tab/Services/Analysis";
//Install
import Category from "./Component/Tab/Category/Default";
//Install
import Upgrade from "./Component/Tab/Category/Update";
//Install
import Client from "./Component/Tab/Client/Default";
//Install
import Order from "./Component/Tab/Client/Order";
//Install
import Update from "./Component/Tab/Client/Update";
//Install
import Contact from "./Component/Tab/Contact/Default";

//Element
function App() {
  //Elements
  return (
    //Element
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/overview" element={ <Overview /> }></Route>
          <Route path="/about" element={ <About /> }></Route>
          <Route path="/media" element={ <Media /> }></Route>
          <Route path="/aim" element={ <Aim /> }></Route>
          <Route path="/services/branding-and-identity" element={ <Branding /> }></Route>
          <Route path="/services/graphic-design" element={ <Graphic /> }></Route>
          <Route path="/services/web-design-and-development" element={ <Development /> }></Route>
          <Route path="/services/social-media-management" element={ <Social /> }></Route>
          <Route path="/services/influencer-marketing" element={ <Influencer /> }></Route>
          <Route path="/services/content-creation" element={ <Creation /> }></Route>
          <Route path="/services/marketing-campaign" element={ <Campaign /> }></Route>
          <Route path="/services/photography-and-video" element={ <Photography /> }></Route>
          <Route path="/services/copywriting" element={ <Copywriting /> }></Route>
          <Route path="/services/seo-and-digital-marketing" element={ <SEO /> }></Route>
          <Route path="/services/event-planning-and-management" element={ <Event /> }></Route>
          <Route path="/services/brand-monitoring-and-analysis" element={ <Analysis /> }></Route>
          <Route path="/category" element={ <Category /> }></Route>
          <Route path="/category-update/:ID" element={ <Upgrade /> }></Route>
          <Route path="/client" element={ <Client /> }></Route>
          <Route path="/client-order" element={ <Order /> }></Route>
          <Route path="/client-edit/:ID" element={ <Update /> }></Route>
          <Route path="/contact" element={ <Contact /> }></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
//Element
export default App;
