//Install
import React, { useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Card from "./Card";
//Install
import Product from "./Product";
//Install
import Header from "../Header";
//Install
import Footer from "../Footer";

//Element
function Q192Y0(){
    //Element
    document.title = "Hello - Cover Studio"
}
//Element
function Default(){
    //Element
    Q192Y0()
    //Element
    let YS014L = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
    //Element
    let E34245 = new Date()
    //Element
    let E89J9A = E34245.getDate()
    //Element
    let L0047O = YS014L[ E34245.getMonth() ]
    //Element
    let H8E93O = E34245.getFullYear()
    //Element
    let R991JQ = E34245.getHours()
    //Element
    let A4621K = E34245.getMinutes()
    //Element
    let B348QM = ":"
    //Element
    let P10047 = " "
    //Element
    let D767O5 = E89J9A + P10047 + L0047O + P10047 + H8E93O + P10047
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Hello, Cover Studio", Status: D767O5 } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 q0r45m" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        React.createElement( Card, { ID: "0", Title: "Services", Target: "/" } )
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        React.createElement( Card, { ID: "0", Title: "Our Client(s)", Target: "/" } )
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        React.createElement( Card, { ID: "0", Title: "Subscription", Target: "/" } )
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        React.createElement( Card, { ID: "0", Title: "Blog", Target: "/" } )
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        React.createElement( Card, { ID: "0", Title: "Media", Target: "/" } )
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        React.createElement( Card, { ID: "0", Title: "Contact", Target: "/" } )
                    )
                )
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 e279bh" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:8 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 pb-4 m-0 mt-4 w60023" },
                            //Element 
                            React.createElement( "h3", { className: "float-start w-100 p-0 pb-3 m-0 k3gq53" },
                                //Element
                                ""
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 z336ry" },
                                
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 pb-4 m-0 mt-4 b129uj" },
                            //Element
                            React.createElement( "h3", { className: "float-start w-100 p-0 pb-3 m-0 el8h94" },
                                //Element 
                                ""
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 w123l4" },
                                
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 pb-4 m-0 mt-4 b129uj" },
                            //Element
                            React.createElement( "h3", { className: "float-start w-100 p-0 pb-3 m-0 el8h94" },
                                //Element 
                                ""
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 w123l4" },
                                
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;